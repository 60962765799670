import { render, staticRenderFns } from "./JumboHeroBanner.vue?vue&type=template&id=2fd7a834&scoped=true"
import script from "./JumboHeroBanner.vue?vue&type=script&lang=ts"
export * from "./JumboHeroBanner.vue?vue&type=script&lang=ts"
import style0 from "./JumboHeroBanner.vue?vue&type=style&index=0&id=2fd7a834&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd7a834",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RenderPOI: require('/var/www/modules/amplience/components/render/POI.vue').default,SkeletonJumboHeroBanner: require('/var/www/modules/amplience/components/skeleton/JumboHeroBanner.vue').default,RenderRichText: require('/var/www/modules/amplience/components/render/RichText.vue').default})
