





















import { PropType } from 'vue';
import { RichTextItemInterface } from '~/modules/amplience/types';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RenderRichText',
  props: {
    items: {
      type: Array as PropType<RichTextItemInterface[]>,
      default: () => [],
    },
  }
})
