

















































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  watch
} from '@nuxtjs/composition-api';
import { SfHeading, SfButton} from '@storefront-ui/vue';
import { PropType } from 'vue';
import {
  CtaInterface,
  ImageHolderInterface,
  RichTextItemInterface,
} from '~/modules/amplience/types';
import { useWindowSize, useScreenOrientation } from '@vueuse/core';
import { debounce } from 'lodash-es';
import { getAspectRatio } from '~/modules/amplience/helpers/getAspectRatio';

export default defineComponent({
  name: 'JumboHeroBanner',
  components: {
    SfHeading,
    SfButton,
  },
  props: {
    media :{
      type: String,
      default: 'None',
    },
    imageHolder: {
      type: [Object, null] as PropType<ImageHolderInterface | null>,
      default: null,
    },
    video: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: '',
    },
    headingColor: {
      type: String,
      default: 'dark',
    },
    description: {
      type: Array as PropType<RichTextItemInterface[]>,
      default: () => [],
    },
    contentPosition: {
      type: String,
      default: 'left',
    },
    actions: {
      type: Array as PropType<CtaInterface[]>,
      default: () => [],
    },
    textContainerView: {
      type: String,
      default: 'internal',
    },
    containerColor: {
      type: String,
      default: 'white',
    }
  },
  setup(props) {
    const headerHeight =  ref<number | null>(null);
    const element = ref<HTMLElement | null>();
    const videoRef = ref<HTMLVideoElement | null>();
    const jumboBannerContent = ref<HTMLElement | null>(null);
    const isHeroImage = (): boolean => !!props.imageHolder?.poi?.image;
    const isCallToAction = (button): boolean => !!(button.cta?.url && button.cta?.type && button.cta?.title);
    const isInternalTextContainer = computed(() => props.textContainerView === 'internal');
    const contentIsConfigured = (): boolean => !!(props.media === 'Image' && isHeroImage() ||
      props.media === 'Video' && props.video ||
      props.media === 'None');
    const { width, height } = useWindowSize();
    const { orientation } = useScreenOrientation();
    let initialHeight = ref<number>(height.value);
    const videoIsLoading = ref<boolean>(true);

    const videoUrl = `https://${props.video.defaultHost}/v/${props.video.endpoint}/${props.video.name}`;
    const desktopVideoSources = [
      {
        type: 'webm',
        quality: '1080p', // 1920 x 1080
        codecs: 'vp8,null'
      },
      {
        type: 'mp4',
        quality: '1080p',
        codecs: 'h264,null'
      },
    ];
    const tabletVideoSources = [
      {
        type: 'webm',
        quality: '720p', // 992 x 720
        codecs: 'vp8,null'
      },
      {
        type: 'mp4',
        quality: '720p',
        codecs: 'h264,null'
      },

    ];
    const mobileVideoSources = [
      {
        type: 'webm',
        quality: '700p', // 576 x 700
        codecs: 'vp8,null'
      },
      {
        type: 'mp4',
        quality: '700p',
        codecs: 'h264,null'
      },
    ]

    const resetHeaderHeight = debounce(
      () => {
        headerHeight.value = 0;
      },
      300
    );

    const isLandscape = computed(() => {
      return orientation.value === 'landscape-primary';
    })

    const resizeHandler = debounce(
      () => {
        if (!headerHeight.value) {
          const previewMessageComponentHeight = document.querySelector('.preview-message-component')?.getBoundingClientRect().height || 0;
          const appHeaderComponentHeight = document.querySelector('.app-header-component')?.getBoundingClientRect().height || 0;
          const notificationGroupComponentHeight = document.querySelector('.notification-group-component')?.getBoundingClientRect().height || 0;
          const beforeContentComponentHeight = document.querySelector('.before-content-component')?.getBoundingClientRect().height || 0;
          headerHeight.value = previewMessageComponentHeight
            + appHeaderComponentHeight
            + notificationGroupComponentHeight
            + beforeContentComponentHeight;
        }
      },
      300
    );

    const mobileImgHeight = computed(() => {
      // minHeightFromAspectRatio is 25% of viewportWidth
      // this means that min aspect ratio is 4:1
      const minHeightFromAspectRatio = width.value / 4;

      // if jumboBannerContent element is present
      // it can be higher than minHeightFromAspectRatio
      const heightOfJumboBannerConetent = jumboBannerContent.value?.getBoundingClientRect().height || 0;

      // if textContainerView is internal (text will be positioned inside the image)
      // calculated minHeight is higher of minHeightFromAspectRatio and heightOfJumboBannerConetent
      // else minHeight is equal to minHeightFromAspectRatio
      const minHeight = isInternalTextContainer.value ? Math.max(minHeightFromAspectRatio, heightOfJumboBannerConetent) : minHeightFromAspectRatio;
      let calculatedHeight = 0;

      // if jumboBannerContent element is not present
      // we calculate height based on viewportHeight and header height
      // JumboBannerContent element will always be first on the page
      if (!jumboBannerContent.value) {
        calculatedHeight = initialHeight.value - headerHeight.value;
      }
      // if textContainerView is internal
      // we calculate height based on viewportHeight and header height
      // text will be positioned inside the image
      else if (isInternalTextContainer.value) {
        calculatedHeight = initialHeight.value - headerHeight.value;
      }
      // if textContainerView is external
      // we also exclude jumbo banner content height
      else {
        calculatedHeight = initialHeight.value - headerHeight.value - heightOfJumboBannerConetent;
      }

      return calculatedHeight < minHeight ? minHeight : calculatedHeight;
    });

    const desktopImgHeight = computed(() => {
      const heightOfJumboBannerConetent = jumboBannerContent.value?.getBoundingClientRect().height || 0;
      let calculatedHeight = 0;

      if (!jumboBannerContent.value) {
        calculatedHeight = initialHeight.value - headerHeight.value;
      }

      else {
        calculatedHeight = Math.max((initialHeight.value - headerHeight.value), heightOfJumboBannerConetent);
      }

      return calculatedHeight;
    });

    const sources = computed(() => {
      return [
        {
          aspect: getAspectRatio(width.value, mobileImgHeight.value),
          height: mobileImgHeight.value,
          media: '(max-width: 992px)',
        },
        {
          aspect: getAspectRatio(width.value, initialHeight.value - headerHeight.value),
          height: desktopImgHeight.value,
          media: '(min-width: 993px)',
        },
      ]
    });

    const playVideo = () => {
      const checkVideo = setInterval(() => {
        if (videoRef.value) {
          videoRef.value.play();
          videoIsLoading.value = false;

          clearInterval(checkVideo);
        }
      }, 1000);
    }

    watch(
      [
        () => orientation.value,
        () => width.value
      ],
      () => {
        initialHeight.value = height.value;
      }
    );

    onBeforeMount(() => {
      if (props.media === 'Video') {
        playVideo();
      }
    })

    onMounted(() => {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      screen.orientation.addEventListener('change', resetHeaderHeight);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler);
      screen.orientation.removeEventListener('change', resetHeaderHeight);
    });

    return {
      contentIsConfigured,
      isHeroImage,
      isCallToAction,
      playVideo,
      isLandscape,
      element,
      headerHeight,
      videoRef,
      videoUrl,
      videoIsLoading,
      desktopVideoSources,
      tabletVideoSources,
      mobileVideoSources,
      width,
      sources,
      jumboBannerContent,
      mobileImgHeight,
      desktopImgHeight,
      orientation
    };
  }
});
