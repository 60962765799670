









import { defineComponent } from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'SkeletonJumboHeroBanner',
  components: {
    SfLoader,
  },
  props: {
    height: {
      type: Number,
      default: 0,
    },
  },
});
