








import { marked } from 'marked';
import { defineComponent } from '@nuxtjs/composition-api';
import CustomRenderer from '~/modules/amplience/classes/CustomRenderer';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'RenderMarkdown',
  components: {
    HTMLContent,
  },
  props: {
    data: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      marked,
      customRenderer: new CustomRenderer,
    };
  },
});
